import * as Yup from 'yup';
import {
  CARD_NUM_REGEX, DISP_ADDRESS_REGEX,
  NUMBER_REGEX, VEHICLE_NUMBER_REGEX
} from '../utils/constants';

// site form model
export const transactionFormModel = {
  formId: 'transactionConfig',
  formField: {
    address: {
      name: 'address',
      label: 'Dispenser Address',
      requiredErrorMsg: 'Dispenser Address is required',
      invalidErrorMsg: 'Dispenser Address is invalid',
      type: 'text',
    },
    number: {
      name: 'number',
      label: 'Transaction Number',
      requiredErrorMsg: 'Transaction Number is required',
      invalidErrorMsg: 'Transaction Number is invalid',
      type: 'text',
    },
    orderId: {
      name: 'order_id',
      label: 'Order ID',
      requiredErrorMsg: 'Order ID is required',
      invalidErrorMsg: 'Invalid order ID',
      type: 'text',
    },
    nozzleNumber: {
      name: 'nozzle_num',
      label: 'Nozzle Number',
      requiredErrorMsg: 'Nozzle number is required',
      invalidErrorMsg: 'Nozzle number is invalid',
      type: 'text',
    },
    quantity: {
      name: 'quantity',
      label: 'Fuel Quantity',
      requiredErrorMsg: 'Fuel Quantity is required',
      invalidErrorMsg: 'Fuel Quantity is invalid',
      type: 'text',
    },
    amount: {
      name: 'amount',
      label: 'Total Amount',
      requiredErrorMsg: 'Total Amount is required',
      invalidErrorMsg: 'Total Amount is invalid',
      type: 'text',
    },
    unitPrice: {
      name: 'unit_price',
      label: 'Unit Price',
      requiredErrorMsg: 'Unit Price is required',
      invalidErrorMsg: 'Unit Price is Invalid',
      type: 'text',
    },
    vehicleCard: {
      name: 'card_num_1',
      label: 'Vehicle Card',
      requiredErrorMsg: 'Vehicle Card is required',
      invalidErrorMsg: 'Vehicle Card is invalid',
      type: 'text',
    },
    operatorCard: {
      name: 'card_num_2',
      label: 'Operator Card',
      requiredErrorMsg: 'Operator Card is required',
      invalidErrorMsg: 'Operator Card is invalid',
      type: 'text',
    },
    odometerEntry: {
      name: 'odometer_entry',
      label: 'Odometer Entry',
      requiredErrorMsg: 'Odometer Entry is required',
      invalidErrorMsg: 'Odometer Entry is invalid',
      type: 'text',
    },
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Vehicle Number',
      requiredErrorMsg: 'Vehicle Number is required',
      invalidErrorMsg: 'Vehicle Number is invalid',
      type: 'text',
    },
    totalizerStartReading: {
      name: 'totalizer_start_reading',
      label: 'Totalizer Start Reading',
      requiredErrorMsg: 'Totalizer Start Reading is required',
      invalidErrorMsg: 'Totalizer Start Reading is invalid',
      type: 'text',
    },
    totalizerEndReading: {
      name: 'totalizer_end_reading',
      label: 'Totalizer End Reading',
      requiredErrorMsg: 'Totalizer End Reading is required',
      invalidErrorMsg: 'Totalizer End Reading is invalid',
      type: 'text',
    },
    startTime: {
      name: 'start_time',
      label: 'Start Time',
      requiredErrorMsg: 'Start Time is required',
      invalidErrorMsg: 'Start Time is invalid',
      type: 'text',
    },
    endTime: {
      name: 'end_time',
      label: 'End Time',
      requiredErrorMsg: 'End Time is required',
      invalidErrorMsg: 'End Time is invalid',
      type: 'text',
    },
    compartment: {
      name: 'compartment',
      label: 'Compartment',
      requiredErrorMsg: 'Compartment is required',
      invalidErrorMsg: 'Compartment is invalid',
      type: 'text',
    },
    maxFlowRate: {
      name: 'max_flow_rate',
      label: 'Max Flow Rate',
      requiredErrorMsg: 'Max Flow Rate is required',
      invalidErrorMsg: 'Max Flow Rate is invalid',
      type: 'text',
    },
    minFlowRate: {
      name: 'min_flow_rate',
      label: 'Min Flow Rate',
      requiredErrorMsg: 'Min Flow Rate is required',
      invalidErrorMsg: 'Min Flow Rate is invalid',
      type: 'text',
    },
    avgFlowRate: {
      name: 'avg_flow_rate',
      label: 'Avg fFlow Rate',
      requiredErrorMsg: 'Avg fFlow Rate is required',
      invalidErrorMsg: 'Avg fFlow Rate is invalid',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    address,
    number,
    orderId,
    nozzleNumber,
    quantity,
    amount,
    unitPrice,
    vehicleCard,
    operatorCard,
    odometerEntry,
    vehicleNumber,
    totalizerStartReading,
    totalizerEndReading,
    startTime,
    endTime,
    compartment,
    maxFlowRate,
    minFlowRate,
    avgFlowRate,
  }
} = transactionFormModel;

export const transactionFormInitialValues = {
  [address.name]: '',
  [number.name]: '',
  [orderId.name]: '',
  [nozzleNumber.name]: '',
  [quantity.name]: '',
  [amount.name]: '',
  [unitPrice.name]: '',
  [vehicleCard.name]: '',
  [operatorCard.name]: '',
  [odometerEntry.name]: '',
  [vehicleNumber.name]: '',
  [totalizerStartReading.name]: '',
  [totalizerEndReading.name]: '',
  [startTime.name]: '',
  [endTime.name]: '',
  [compartment.name]: '',
  [maxFlowRate.name]: '',
  [minFlowRate.name]: '',
  [avgFlowRate.name]: '',
};

// validation schema
export const transactionValidationSchema = Yup.object().shape({
  [address.name]: Yup.string().matches(DISP_ADDRESS_REGEX, address.invalidErrorMsg).required(address.requiredErrorMsg),
  [number.name]: Yup.number().max(9999999, number.invalidErrorMsg)
    .required(number.requiredErrorMsg),
  [orderId.name]: Yup.number().max(9999999, orderId.invalidErrorMsg).required(orderId.requiredErrorMsg),
  [nozzleNumber.name]: Yup.number().max(10, nozzleNumber.invalidErrorMsg).required(nozzleNumber.requiredErrorMsg),
  [quantity.name]: Yup.number().required(quantity.requiredErrorMsg).typeError(quantity.invalidErrorMsg),
  [unitPrice.name]: Yup.number().required(unitPrice.requiredErrorMsg).typeError(unitPrice.invalidErrorMsg),
  [vehicleCard.name]: Yup.string().matches(CARD_NUM_REGEX, vehicleCard.invalidErrorMsg).required(vehicleCard.requiredErrorMsg),
  [operatorCard.name]: Yup.string().matches(CARD_NUM_REGEX, operatorCard.invalidErrorMsg).required(operatorCard.requiredErrorMsg),
  [vehicleNumber.name]: Yup.string().nullable().required(vehicleNumber.requiredErrorMsg).matches(VEHICLE_NUMBER_REGEX, vehicleNumber.invalidErrorMsg),
  [odometerEntry.name]: Yup.string().matches(NUMBER_REGEX ,odometerEntry.invalidErrorMsg),
  [totalizerStartReading.name]: Yup.number().typeError(totalizerStartReading.invalidErrorMsg),
  [totalizerEndReading.name]: Yup.number().typeError(totalizerEndReading.invalidErrorMsg),
  [startTime.name]: Yup.date().nullable().typeError(startTime.invalidErrorMsg).required(startTime.requiredErrorMsg),
  [endTime.name]: Yup.date().nullable().typeError(endTime.invalidErrorMsg).required(endTime.requiredErrorMsg),
});


//add transaction config
export const addTransactionConfig = {
  name: 'error_string',
  label: 'Error String',
  requiredErrorMsg: 'Error String is required',
  invalidErrorMsg: 'Error String is invalid',
  type: 'text',
};

export const addTransactionValidationSchema = Yup.object().shape({
  [addTransactionConfig.name]: Yup.string().matches(VEHICLE_NUMBER_REGEX, addTransactionConfig.invalidErrorMsg).required(addTransactionConfig.requiredErrorMsg)
});
