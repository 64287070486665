import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { APIService } from './utils/api';
import InputField from './common/InputField';
import AddTransaction from './components/AddTransaction';
import { addTransactionConfig, addTransactionValidationSchema } from './components/transactionFormModel';
import { getFormattedTransactionData } from './components/transaction/transactionService';
import NavBar from './components/NavBar';
import InfoDialog from './common/InfoDialog';

function App() {
  const [showTransaction, setShowTransaction] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(true);

  const formInitValues = {
    error_string: '',
  };

  const onModalClose = () => {
    setShowTransaction(false);
    setFormValues({});
  };

  const onInfoModalClose = () => {
    setShowError(false);
    setShowModal(false);
  };

  const getVehicleDetails = async transactionInfo => {
    setIsLoading(true);
    const cardUID = transactionInfo.card_num_1 ? Number(transactionInfo.card_num_1) : '';
    if (cardUID) {
      try {
        const res = await APIService.fetchCardLimitRequest(cardUID);
        console.log(res);
        if (res.success) {
          const approvalsList = res.result.results;
          const vehicleNum = approvalsList.length > 0 ? approvalsList[0].vehicle_registration_number : '';
          setFormValues({ ...transactionInfo, vehicle_number: vehicleNum });
          setShowTransaction(true);
        } else {
          throw new Error(res);
        }
      } catch (error) {
        console.log(error);
        setShowError(true);
        setShowModal(true);
      }
    } else {
      setFormValues(transactionInfo);
      setShowTransaction(true);
      setIsLoading(false);
    }
  }

  const getMissedTransactionDetails = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        error_string: values.error_string
      };
      const res = await APIService.fetchMissedTransactionDetails(data);
      if (res.success) {
        const transactionInfo = getFormattedTransactionData(res.result.data);
        getVehicleDetails(transactionInfo);
      } else {
        throw new Error(res);
      }
    } catch (error) {
      setShowError(true);
      setShowModal(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
       <NavBar />
      <Grid className="App" sx={{ ml: 1 }}>
        <header className="App-header">
          <h2>Add Missed Transaction</h2>
        </header>
        <InfoDialog open={showModal} isError={showError} handleClose={onInfoModalClose} />
        <Formik
          initialValues={formInitValues}
          validationSchema={addTransactionValidationSchema}
          onSubmit={getMissedTransactionDetails}
        >
          {() => (
            <Form id="missedTransactionForm">
              <Grid container spacing={3} sx={{mt: 2, mb: 4}}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    multiline
                    minRows={3}
                    name={addTransactionConfig.name}
                    label={addTransactionConfig.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {!isLoading ?
                (<Button
                  color="primary"
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  Get Transaction Details
                </Button>) :
                <CircularProgress sx={{ color: theme.palette.success.light }} />
              }
            </Form>
          )}
        </Formik>
        <AddTransaction open={showTransaction} handleClose={onModalClose} initialValues={formValues} />
      </Grid>
    </ThemeProvider>
  );
}

export default App;
