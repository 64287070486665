import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from '../components/Login';
import NotFound from '../components/NotFound';
import App from '../App';
import useAuth from '../navigation/AuthContext';
import { ROUTE } from '../utils/constants';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const auth = useAuth();
  const activeSession = auth.isAuth();
  if (!activeSession) {
    return <Redirect to={ROUTE.login} />;
  }

  return <Route component={Component} path={path} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string
};

const AuthRoute = ({ component: Component, path, ...rest }) => {
  const auth = useAuth();
  const activeSession = auth.isAuth();
  if (activeSession) {
    return <Redirect to="/dashboard" />;
  }

  return <Route component={Component} path={path} {...rest} />;
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string
};

const MainRoute = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to={ROUTE.login} />} />
      <AuthRoute path={ROUTE.login} component={Login} />
      <PrivateRoute path={ROUTE.app} component={App} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default MainRoute;
