import * as Yup from 'yup';
import { AES, enc } from "crypto-js";
import { BASEAPI } from "./api";

export const STORAGE_KEY = {
  token: 'fms_auth_token',
  user: 'fms_user'
};

export const ROUTE = {
  login: '/login',
  app: '/dashboard',
}

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(80)
    .required('Email is required'),
  password: Yup.string().max(40).required('Password is required')
});

// form validation regex
export const ADDITONAL_ENTRY_REGEX = /^[1-9]/;
export const NUMBER_REGEX = /^\d+$/;
export const PINCODE_REGEX = /^[0-9]{6}$/;
export const NAME_REGEX = /^[a-zA-Z]+$/;
export const USER_NAME_REGEX = /^[a-zA-Z]{2,}\d*$/;
export const ENTITY_NAME_REGEX = /^[a-zA-Z0-9\-\s]+$/;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,).('-]*$/;
export const CODE_REGEX = /^[a-zA-Z0-9]+$/;
export const INVOICE_NUM_REGEX = /^[a-zA-Z0-9\s)/_(-]*$/;
// export const VEHICLE_NUMBER_REGEX = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}[0-9]{4}$/;
export const VEHICLE_NUMBER_REGEX = /^[a-zA-Z0-9\s,)_(&-]*$/;
export const LATITUDE_REGEX = /^((-?|\+?)?\d+(\.\d+)?)$/;
export const LONGITUDE_REGEX = /^s*((-?|\+?)?\d+(\.\d+)?)$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const DISP_ADDRESS_REGEX = /^\d{4}$/;
export const NOZZEL_NUM_REGEX = /^\d{2}$/;
export const CARD_NUM_REGEX = /^\d{20}$/;
export const TRANSACTION_NUM_REGEX = /^\d{7}$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!*%^#@$&])[a-zA-Z0-9!*%^#@$&]{8,}$/;
// common error msg
export const NUMBER_TYPE_ERROR_MSG = 'Must be a number';
export const DATE_TYPE_ERROR_MSG = 'Invalid Date';
const REACT_APP_SECRET_KEY = "bf01f3a3-2ece-43e6-b96e-96bd77c75083";

const encryptData = (data) => {
  let value = data;
  if (typeof data !== 'object') {
    value = JSON.stringify(data);
  }
  const cipherText = AES.encrypt(
    value,
    REACT_APP_SECRET_KEY
  ).toString();
  return cipherText;
};

const decryptData = (cipherText) => {
  const bytes = AES.decrypt(cipherText, REACT_APP_SECRET_KEY);
  const originalText = bytes.toString(enc.Utf8);
  return originalText.replace(/["]+/g, ''); // to remove double quotes ;
};

export const storeLocalData = (key, data, encrypt) => {
  let dataToStore = data;
  if (typeof data === 'object') {
    dataToStore = JSON.stringify(data);
  }
  if (encrypt) {
    dataToStore = encryptData(dataToStore);
  }
  // console.log('data to store', key, dataToStore);
  localStorage.setItem(key, dataToStore);
};

export const getLocalData = (key, parse, decrypt) => {
  let dataToFetch = localStorage.getItem(key);
  if (!dataToFetch) return null;
  if (decrypt) {
    dataToFetch = decryptData(dataToFetch);
  }
  if (parse) {
    dataToFetch = JSON.parse(dataToFetch);
  }
  // console.log('data To fetch', key, dataToFetch);
  return dataToFetch;
};

export const clearLocalData = () => {
  localStorage.clear();
  delete BASEAPI.defaults.headers.common.Authorization;
};