/* eslint-disable react/prop-types */
import React, { useContext, createContext, useState } from 'react';
import { createBrowserHistory } from 'history';
import { STORAGE_KEY, clearLocalData, getLocalData, storeLocalData } from '../utils/constants';
import { APIService,  setAPIAuthHeader } from '../utils/api';

const authContext = createContext();

function useProvideAuth() {
  const history = createBrowserHistory();
  const [user, setUser] = useState(null);

  const signin = (data) => {
    const userData = { ...data.user, role: data.role };
    setUser(userData);
    storeLocalData(STORAGE_KEY.user, userData);
    setAPIAuthHeader(data.token);
    storeLocalData(STORAGE_KEY.token, data.token, true);
  };

  const isAuth = () => {
    const token = getLocalData(STORAGE_KEY.token, false, true);
    if (token) {
      setAPIAuthHeader(token);
    }
    return token;
  };

  const getUser = () => {
    let userInfo = user;
    if (!user) {
      userInfo = getLocalData(STORAGE_KEY.user, true);
    }
    return userInfo;
  };

  const clearCacheAndRedirect = () => {
    setUser(null);
    clearLocalData();
    history.go(0);
  };

  const signout = async () => {
    try {
      const body = { is_mobile: false };
      await APIService.userLogout(body);
      clearCacheAndRedirect();
    } catch (error) {
      console.log('logout error', error);
    }
  };

  return {
    getUser,
    isAuth,
    signin,
    signout,
    clearCacheAndRedirect
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function useAuth() {
  return useContext(authContext);
}
