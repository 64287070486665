import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { colors, Stack, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';

export default function DatePickerField({
  label, name, disabled, onlyDate = false, ...rest
}) {
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;
  const dateFormat = onlyDate ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss';
  const displayFormat = onlyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm';

  const onDateChange = (date) => {
    try {
      const dateString = date.format(dateFormat);
      formikProps.setFieldValue(name, dateString, true);
    } catch (e) {
      formikProps.setFieldValue(name, null, true);
    }
  };

  function renderHelperText() {
    if (meta.touched && meta.error) {
      return meta.error;
    }
    return null;
  }

  const PickerComponent = onlyDate ? DatePicker : DateTimePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <PickerComponent
          {...field}
          {...rest}
          onChange={onDateChange}
          label={label}
          inputFormat={displayFormat}
          value={selectedValue}
          readOnly={disabled}
          renderInput={(props) => (
            <TextField
              {...props}
              sx={{ backgroundColor: disabled ? colors.grey[200] : 'inherit' }}
              disabled={disabled}
              error={Boolean(meta.touched && meta.error)}
              helperText={renderHelperText()}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}

DatePickerField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onlyDate: PropTypes.bool
};
