import moment from 'moment';

const getFormattedValue = value => {
  if (value && value !== '.') {
    return value;
  } else {
    return 0;
  }
}
const getFormattedTransactionData = transaction => {
  const formattedTransaction = {
    ...transaction,
    site: transaction.site_id,
    compartment: transaction.compartment || "0",
    max_flow_rate: transaction.max_flow_rate || "0065",
    min_flow_rate: transaction.min_flow_rate || "5316",
    avg_flow_rate: transaction.avg_flow_rate || "6800",
    odometer_entry: transaction.odometer_entry || "0",
    limit_type: transaction.limit_type || "No Limit",
    totalizer_start_reading: getFormattedValue(transaction.totalizer_start_reading),
    totalizer_end_reading: getFormattedValue(transaction.totalizer_end_reading),
    start_time: transaction.start_time || moment().format('YYYY-MM-DDTHH:mm:ss'),
    end_time: transaction.end_time || moment().format('YYYY-MM-DDTHH:mm:ss'),
    average_temp: getFormattedValue(transaction.average_temp),
    density_start_reading: getFormattedValue(transaction.density_start_reading),
    density_end_reading: getFormattedValue(transaction.density_end_reading),
    vat_amount: getFormattedValue(transaction.vat_amount),
    opening_fuel_level: getFormattedValue(transaction.opening_fuel_level),
    ending_fuel_level: getFormattedValue(transaction.ending_fuel_level),
    preset_volume: getFormattedValue(transaction.preset_volume),
    no_suspends: getFormattedValue(transaction.no_suspends),
    no_zero_fills: getFormattedValue(transaction.no_zero_fills),
  };
  return formattedTransaction;
};

const getFormattedCardValue = (uid) => {
  const base_uid = '00000000000000000000';
  const card_uid = Number(uid).toString();
  const index = base_uid.length - card_uid.length;
  const formattedCardUID = base_uid.substring(0, index) + card_uid + base_uid.substring(index + card_uid.length);
  return formattedCardUID;
};

export { getFormattedTransactionData, getFormattedCardValue };
