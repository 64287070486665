import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function InfoDialog({open, isError = false, handleClose}) {
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
        {isError ? 'Error' : 'Success'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
          {isError ? 'Something went wrong! Please try again later.' : 'Missed Transaction has been updated successfully!'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}