import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from '../navigation/AuthContext';
import { APIService } from '../utils/api';
import InputField from '../common/InputField';
import { loginValidationSchema } from '../utils/constants';
import { ROUTE } from '../utils/constants';

export const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: 'skyblue',
});

const Login = () => {
  const history = useHistory();
  const auth = useAuth();
  const [loginError, setLoginError] = useState('');
  const initValues = { email: '', password: '', role: 'admin' };

  const login = async (values) => {
    setLoginError('');
    try {
      const res = await APIService.userLogin(values);
      if (!res.success) throw res;
      if (res.result.token) {
        auth.signin(res.result);
        history.replace(ROUTE.app);
      }
    } catch (error) {
      setLoginError('Invalid credentials, Please try again!');
      console.log('Login error', error.message);
    }
  };

  return (
    <LayoutWrapper>
      <Box sx={{
        display: 'flex',
        margin: { sm: '0', md: 'auto' },
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
          <Box sx={{ flex: 1 }}>
            <Card
              variant="elevation"
              sx={{
                py: 4,
                px: 6,
                overflowY: 'auto',
                maxHeight: 452,
                minHeight: 452
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography color="primary" variant="h3" sx={{ pb: 1, fontWeight: 800, letterSpacing: 0.8 }}>
                  ReTrack Backoffice
                </Typography>
              </Box>
              <Formik
                initialValues={initValues}
                validationSchema={loginValidationSchema}
                onSubmit={login}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <InputField
                      name="email"
                      label="Email"
                      margin="normal"
                      fullWidth
                      disabled={isSubmitting}
                    />
                    <InputField
                      name="password"
                      label="Password"
                      type="password"
                      margin="normal"
                      fullWidth
                      disabled={isSubmitting}
                    />
                    <FormHelperText error>{loginError}</FormHelperText>
                    <Box sx={{ py: 2, textAlign: 'center' }}>
                      {isSubmitting ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          sx={{ mt: 2, py: 1.5 }}
                          variant="contained"
                        >
                          Sign in
                        </Button>
                      )}
                    </Box>
                  </form>
                )}
              </Formik>
            </Card>
          </Box>
      </Box>
    </LayoutWrapper>
  );
};

export default Login;
