import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import theme from '../theme';
import Loader from '../common/Loader';
import FMSModal from '../common/FMSModal';
import InputField from '../common/InputField';
import DatePickerField from '../common/DatePickerField';
import {
  transactionFormInitialValues,
  transactionFormModel,
  transactionValidationSchema
} from './transactionFormModel';
import InfoDialog from '../common/InfoDialog';
import { APIService } from '../utils/api';
import { getFormattedCardValue } from './transaction/transactionService';

const AddTransaction = ({ open, handleClose, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(true);
  const [vehicleCardUID, setVehicleCardUID] = useState(initialValues?.card_num_1);

  const formInitValues = initialValues || transactionFormInitialValues;
  const {
    formId,
    formField: {
      address,
      number,
      orderId,
      nozzleNumber,
      quantity,
      amount,
      unitPrice,
      vehicleCard,
      operatorCard,
      odometerEntry,
      vehicleNumber,
      totalizerStartReading,
      totalizerEndReading,
      startTime,
      endTime,
    }
  } = transactionFormModel;

  const onInfoModalClose = () => setShowModal(false);

  const onSubmit = async (values) => {
    setLoading(true);
    showError && setShowError(false);
    try {
      const res = await APIService.addMissedTransaction(values);
      if (res.success) {
        handleClose();
      } else {
        throw new Error(res);
      }
    } catch (error) {
      // handle error here
      setShowError(true);
      console.log('error', error);
    } finally {
      setShowModal(true);
      setLoading(false);
    }
  };

  const formikProps = useFormik({
    initialValues: formInitValues,
    validationSchema: transactionValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const fetchCardDetails = async (vehicleNum, cardUID) => {
    try {
    setLoading(true);
    const transactionRes = await APIService.fetchTransactionList(vehicleNum);
    if (transactionRes.success) {
      const transactionList = transactionRes.result.results;
      const transaction = transactionList.length > 0 ? transactionList[0] : null;
      if (transaction) {
        const formattedCardID = getFormattedCardValue(cardUID);
        formikProps.setFieldValue(operatorCard.name, transaction.card_num_2, true);
        formikProps.setFieldValue(vehicleCard.name, formattedCardID, true);
      }
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicleDetails = async uid => {
    setLoading(true);
    const cardUID = Number(uid);
    try {
      const res = await APIService.fetchCardLimitRequest(cardUID);
      if (res.success) {
        const approvalsList = res.result.results;
        const vehicleNum = approvalsList.length > 0 ? approvalsList[0].vehicle_registration_number : '';
        if (vehicleNum) {
          formikProps.setFieldValue(vehicleNumber.name, vehicleNum, true);
          fetchCardDetails(vehicleNum, cardUID);
        }
      } else {
        throw new Error(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onVehicleCardChange = (e) => {
    const filedProps = formikProps.getFieldProps(vehicleCard.name);
    filedProps.onBlur(e);
    if (filedProps.value !== vehicleCardUID) {
      setVehicleCardUID(filedProps.value);
      fetchVehicleDetails(filedProps.value);
    }
  };

  return (
    <div>
      <InfoDialog open={showModal} isError={showError} handleClose={onInfoModalClose} />
      <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            fontSize: 18,
            background: theme.palette.grey[800],
            color: theme.palette.common.white
          }}
        >
          Missed Transaction Details
        </DialogTitle>
        <Divider />
        <FormikProvider value={formikProps}>
          {loading ? (
            <Loader />
          ) : (
            <Form id={formId} onSubmit={formikProps.handleSubmit}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={address.name}
                      label={address.label}
                      inputProps={{ maxLength: 7 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={number.name}
                      label={number.label}
                      inputProps={{ maxLength: 7 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={orderId.name}
                      label={orderId.label}
                      inputProps={{ maxLength: 7 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={quantity.name}
                      label={quantity.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={unitPrice.name}
                      label={unitPrice.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={amount.name}
                      label={amount.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={nozzleNumber.name}
                      label={nozzleNumber.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={vehicleCard.name}
                      label={vehicleCard.label}
                      inputProps={{ maxLength: 20 }}
                      onBlur={onVehicleCardChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={operatorCard.name}
                      inputProps={{ maxLength: 20 }}
                      label={operatorCard.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={vehicleNumber.name}
                      label={vehicleNumber.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={odometerEntry.name}
                      label={odometerEntry.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={totalizerStartReading.name}
                      label={totalizerStartReading.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name={totalizerEndReading.name}
                      label={totalizerEndReading.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      name={startTime.name}
                      label={startTime.label}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      name={endTime.name}
                      label={endTime.label}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button variant="outlined" size="large" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  Add Transaction
                </Button>
              </DialogActions>
            </Form>
          )}
        </FormikProvider>
      </FMSModal>
    </div>
  );
};

AddTransaction.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default AddTransaction;
