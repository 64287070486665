import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import theme from '../theme';

const Loader = ({ sx }) => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    ...sx
  }}
  >
    <CircularProgress
      size={65}
      sx={{ color: theme.palette.success.light }}
    />
  </Box>
);

Loader.propTypes = {
  sx: PropTypes.object,
};

export default Loader;
