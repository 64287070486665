import axios from 'axios';
import useAuth from '../navigation/AuthContext';

export const API_URL = {
  baseUrl: 'https://www.callcomm.in/',
  login: 'login/',
  logout: 'logout/',
  missedTransaction: 'transaction/missed-transaction-format/',
  addTransaction: '/transaction/add-missed-transaction/',
  transactionList: 'transaction/iot/list/?page=1&page_size=2&vehicle_number={{vehicleNum}}',
  cardLimitRequest: '/cards/limit-request-list/?status=approved&page=1&page_size=2&card__uid={{uid}}'
}

export const SessionManager = () => {
  const auth = useAuth();

  BASEAPI.interceptors.response.use((response) => response,
    (error) => {
      const errorObj = error.response || error.request || error;
      const isFromAuthApi = errorObj?.config?.url === API_URL.login || errorObj?.config?.url === API_URL.logout;
      if (error.response.status === 401 && !isFromAuthApi) {
        auth.clearCacheAndRedirect();
      }
      return Promise.reject(error);
    });
  return <></>
}

export const BASEAPI = axios.create({
  baseURL: API_URL.baseUrl,
  timeout: 12000,
});

export const setAPIAuthHeader = (token) => {
  // Add auth token for all the upcoming API calls
  BASEAPI.defaults.headers.common.Authorization = `Token ${token}`;
};

const fetchData = (method = 'GET', url, data = null) => BASEAPI({
  method,
  url,
  data,
}).then((response) => response.data)
  .catch((error) => {
    const errorInfo = error.response || error.request || error;
    if (error.response) return error.response;
    if (error.request) return error.request;
    return errorInfo;
  });

export default fetchData;

// endpoints
const userLogin = (data) => fetchData('POST', API_URL.login, data);
const userLogout = (data) => fetchData('POST', API_URL.logout, data);
const fetchCardLimitRequest = (cardId) => {
  const url = API_URL.cardLimitRequest.replace('{{uid}}', cardId);
  return fetchData('GET', url);
};
const fetchTransactionList = (vehicleNum) => {
  const url = API_URL.transactionList.replace('{{vehicleNum}}', vehicleNum);
  return fetchData('GET', url);
};
const addMissedTransaction = (data) => fetchData('POST', API_URL.addTransaction, data);
const fetchMissedTransactionDetails = (data) => fetchData('POST', API_URL.missedTransaction, data);

export const APIService = {
  userLogin,
  userLogout,
  fetchCardLimitRequest,
  fetchTransactionList,
  addMissedTransaction,
  fetchMissedTransactionDetails,
}
